import type { LanguageResource } from './types';

const ja: LanguageResource = {
  // app
  app: {
    about: {
      title: 'FMS Console について',
      release_date: 'リリース日時',
    },
  },
  // common
  common: {
    general: {
      name: {
        short: '名',
        original: '名称',
      },
      filter: 'フィルター',
      all: '全',
      data: 'データ',
      domain: 'ドメイン',
      csv: 'CSV',
      show: '表示',
      hide: '非表示',
      type: 'タイプ',
      list: '一覧',
      notification: '通知',
      threshold: '閾値',
      description: '説明',
      id: 'ID',
      config: '設定',
      occurrence: '発生',
      recovery: '復旧',
      information: '情報',
      detail: '詳細',
      version: 'バージョン',
      created_by: '作成者',
      updated_by: '更新者',
      registered_by: '登録者',
      times: '回',
      times_multiple: '回',
      times_with_count: '{{count}}回',
      times_with_count_plural: '{{count}}回',
      behind: '遅れ',
      ahead: '前倒し',
      mileage: '走行距離',
      total: '合計',
      load: '読み込む',
      load_more: '続きを読み込む',
      contents: '内容',
      low: '低下',
      current: '現在',
      transit_time: '移動時間',
      message: 'メッセージ',
      score: 'スコア',
      not_set: '未設定',
      change_to: '{{value}}に変更します',
      updating: '更新中',
      map: '地図',
      software: 'ソフトウェア',
      others: 'その他',
      title: 'タイトル',
      link: 'リンク',
      timezone: 'タイムゾーン',
      summary: '概要',
      shortcutkey: 'ショートカットキー',
      check_document: 'ドキュメントを確認',
    },
    text: {
      none: '{{name}}がありません',
    },
    fms: {
      autoware: 'Autoware',
      project: 'プロジェクト',
      driving_environment: '走行環境',
      stop_point: '停車地点',
      stop_points: '停車地点',
      route: 'ルート',
      schedule: 'スケジュール',
      task: 'タスク',
      place: {
        origin: '出発地',
        destination: '目的地',
        allow_goal_modification: '停車位置変更可',
      },
      map: {
        no_stop_points: {
          title: '地図に停車地点が登録されていません',
          description:
            '停車地点が登録されていないとスケジュール登録等、一部のFMSの機能が利用できません',
        },
      },
      area_map: 'エリアマップ',
      area_map_version: '$t(common.fms.area_map) バージョン',
      pcd_map: 'PCDマップ',
      vector_map: 'Vectorマップ',
      vector_map_builder: 'Vector Map Builder',
    },
    datahub: {
      event: {
        type: {
          shutdown_start: 'シャットダウン期間開始',
          shutdown_end: 'シャットダウン期間終了',
          network_connected: 'ネットワーク接続',
          network_disconnected: 'ネットワーク切断',
        },
      },
    },
    action: {
      action: 'アクション',
      activate: '有効化',
      add: '追加',
      apply: '反映',
      archive: 'アーカイブ',
      back: '戻る',
      cancel: 'キャンセル',
      click: 'クリック',
      close: '閉じる',
      create: '作成',
      change: '変更',
      confirm: '確認',
      deactivate: '無効化',
      edit: '編集',
      delete: '削除',
      deploy: '展開',
      deploy_to_vehicle: '車両に展開',
      deselect: '選択解除',
      download: 'ダウンロード',
      end: '終了',
      generate: '生成',
      get: '取得',
      input: '入力',
      launch: '起動',
      next: '次へ',
      ok: 'OK',
      open: '開く',
      prev: '前へ',
      record: '記録',
      refresh: '再読み込み',
      register: '登録',
      remove: '削除',
      reload: '再読み込み',
      reset: 'リセット',
      restore: '復元',
      return: '戻る',
      save: '保存',
      search: '検索',
      select: '選択',
      start: '開始',
      stop: '停止',
      update: '更新',
    },
    status: {
      status: '状態',
      available: '有り',
      not_available: '無し',
      success: '成功',
      failure: '失敗',
      failed: '失敗',
      unknown: '不明',
      complete: '完了',
      completed: '完了',
      in_progress: '進行中',
      generating: '生成中',
      deleted: '削除済み',
      deleting: '削除中',
      deletion_failed: '削除失敗',
      no_deployments: '未展開',
      never_executed: '未実行',
      valid: '有効',
      invalid: '無効',
      updatable: '更新可',
      non_updatable: '更新不可',
      active: '有効',
      activating: '有効化中',
      deactivating: '無効化中',
      inactive: '無効',
      restoring: '復元中',
      archiving: 'アーカイブ中',
      changing: '変更中',
      registering: '登録中',
      copied: 'コピーしました',
      undefined: '不明',
      created: '作成済み',
      creating: '作成中',
      creation_failed: '作成失敗',
      not_created: '未作成',
    },
    date: {
      date: '日付',
      time: '時間',
      date_and_time: '日時',
      year_and_month: '年月',
      created_at: '作成日時',
      updated_at: '更新日時',
      registered_at: '登録日時',
      published_at: '公開日時',
      start_date: '開始日',
      end_date: '終了日',
      start_date_time: '開始日時',
      end_date_time: '終了日時',
      start_time: '開始時刻',
      end_time: '終了時刻',
      period: '期間',
      unit: {
        day: {
          short: '日',
          original: '日',
          original_plural: '{{count}}日',
        },
        hour: {
          short: '時間',
          original: '時間',
          original_plural: '{{count}}時間',
        },
        minute: {
          short: '分',
          original: '分',
          original_plural: '{{count}}分',
        },
        second: {
          short: '秒',
          original: '秒',
          original_plural: '{{count}}秒',
        },
      },
      invalid: {
        date: '無効な日付です',
        time: '無効な時刻です',
        year_and_month: '無効な年月です',
      },
    },
    day_of_week: {
      short: {
        monday: '月',
        tuesday: '火',
        wednesday: '水',
        thursday: '木',
        friday: '金',
        saturday: '土',
        sunday: '日',
      },
      original: {
        monday: '月曜日',
        tuesday: '火曜日',
        wednesday: '水曜日',
        thursday: '木曜日',
        friday: '金曜日',
        saturday: '土曜日',
        sunday: '日曜日',
      },
    },
    attention: {
      select: {
        vehicle: '車両を選択してください',
        date: '日付を選択してください',
      },
      input: {
        empty: '入力してください',
        half_size: '半角スペースを除く半角文字のみ入力可能です',
        too_long: '長すぎます',
        too_short: '短すぎます',
        not_match: '一致していません',
      },
      date: {
        invalid_format: 'フォーマットが不正です',
        date_time: {
          start_date_time_after_end_date_time:
            '開始日時は終了日時の前に設定してください',
          end_date_time_before_start_date_time:
            '終了日時は開始日時より後に設定してください',
        },
        date: {
          end_date_before_start_date: '終了日は開始日より後に設定してください',
          within_days: '期間は{{count}}日以内になるように指定してください',
        },
        time: {
          not_set: '時刻が設定されていません',
          not_correctly: '時刻が正しく設定されていません',
          before_current_time: '現在時刻より前に設定されています',
          after_current_time: '現在時刻より後に設定されています',
          start_time_after_end_time:
            '開始時刻は終了時刻より前に設定してください',
          end_time_before_start_time:
            '終了時刻は開始時刻より後に設定してください',
          same_time: '開始・終了時刻が同じになっています',
          range_over_one: '{{count}}日以内に設定してください',
          range_over_other: '{{count}}日以内に設定してください',
        },
      },
      once_setting: '一度設定すると変更出来ません',
      mileage: '手動運転時の距離も含みます',
    },
    alert: {
      battery: 'バッテリーが{{count}}%以下になりました',
    },
  },
  api: {
    cause_unknown: '原因不明',
    status: {
      failed: 'に失敗しました',
      successfully: 'に成功しました',
    },
    service_status:
      '{{service_name}} のサービスステータス取得$t(api.status.{{status}})',
    auth: {
      get_projects: 'プロジェクトの取得$t(api.status.{{status}})',
      post_assert: '権限情報の取得$t(api.status.{{status}})',
      post_authorization: '認可情報の取得$t(api.status.{{status}})',
    },
    datahub: {
      get_diagnostics: 'Diagnostics 情報の取得$t(api.status.{{status}})',
      get_events: 'イベントデータの取得$t(api.status.{{status}})',
      get_vehicles_driven: '車両一覧の取得$t(api.status.{{status}})',
      get_manual_overrides: '手動運転介入情報の取得$t(api.status.{{status}})',
      get_emergencies: 'Emergency 情報の取得$t(api.status.{{status}})',
      get_probes: 'Probe データの取得$t(api.status.{{status}})',
      get_log_files: '{{logType}} ファイルの取得$t(api.status.{{status}})',
      download_syslog_files:
        'Syslog ファイルのダウンロード$t(api.status.{{status}})',
      get_metrics: '車両の稼働時間・走行距離の取得$t(api.status.{{status}})',
      get_metrics_daily: '走行データの取得$t(api.status.{{status}})',
      get_scan_matching_score:
        '自己位置推定スコアの取得$t(api.status.{{status}})',
      get_scan_matching_score_type:
        '自己位置推定スコアタイプの取得$t(api.status.{{status}})',
      get_weather_locations: '天気情報の取得$t(api.status.{{status}})',
      get_weather_forecasts: '天気予報の取得$t(api.status.{{status}})',
      get_weather_observation: '天気観測情報の取得$t(api.status.{{status}})',
      get_driving_route_data: '走行経路の取得$t(api.status.{{status}})',
      delete_driving_note: '走行メモの削除$t(api.status.{{status}})',
      get_driving_note: '走行メモの情報取得$t(api.status.{{status}})',
      get_driving_notes: '走行メモの一覧取得$t(api.status.{{status}})',
      patch_driving_note: '走行メモの編集登録$t(api.status.{{status}})',
      post_driving_note: '走行メモの登録$t(api.status.{{status}})',
      get_timezone: 'タイムゾーンの取得$t(api.status.{{status}})',
      get_maintenance_log: 'ログの取得$t(api.status.{{status}})',
      patch_maintenance_log: 'ログの更新$t(api.status.{{status}})',
      post_uptime_reset: 'システム稼働時間のリセット$t(api.status.{{status}})',
      post_log_files_batch_downloads:
        'ファイル情報の取得$t(api.status.{{status}})',
    },
    fms: {
      delete_time_table: '運行ダイヤ設定の削除$t(api.status.{{status}})',
      delete_vehicle: '{{name}}の削除$t(api.status.{{status}})',
      delete_vehicle_certificate: '証明書の削除$t(api.status.{{status}})',
      delete_environment: '走行環境の削除$t(api.status.{{status}})',
      get_active_schedule:
        '現在のスケジュール情報の取得$t(api.status.{{status}})',
      get_area_maps: 'エリアマップ一覧の取得$t(api.status.{{status}})',
      get_area_map_service_quota:
        'エリアマップのサービスクォータ取得$t(api.status.{{status}})',
      get_area_map_versions:
        'エリアマップバージョン一覧の取得$t(api.status.{{status}})',
      get_catalogs: 'カタログ情報の取得$t(api.status.{{status}})',
      get_calibration_parameter_graph_files:
        '候補パラメータのグラフファイルの取得$t(api.status.{{status}})',
      get_calibration_parameter_detail:
        'パラメータの詳細情報の取得$t(api.status.{{status}})',
      get_environments: '走行環境情報の取得$t(api.status.{{status}})',
      get_editable_vehicle_calibration_parameters:
        '編集可能候補パラメータの取得$t(api.status.{{status}})',
      get_firmware_entry_list: 'Firmware情報の取得$t(api.status.{{status}})',
      get_lanelets_center_line_points: 'Laneletの取得$t(api.status.{{status}})',
      get_places: '停車地点一覧の取得$t(api.status.{{status}})',
      get_route: 'ルート情報の取得$t(api.status.{{status}})',
      get_route_search: 'ルート検索$t(api.status.{{status}})',
      get_route_search_current_position: 'ルート検索$t(api.status.{{status}})',
      get_schedule: 'スケジュール情報の取得$t(api.status.{{status}})',
      get_schedules: 'スケジュール情報の取得$t(api.status.{{status}})',
      get_tasks: 'タスク一覧の取得$t(api.status.{{status}})',
      get_time_tables: '運行ダイヤ設定の取得$t(api.status.{{status}})',
      get_vehicle: '車両情報の取得$t(api.status.{{status}})',
      get_vehicle_parameters:
        '車両固有パラメータの取得$t(api.status.{{status}})',
      get_vehicle_parameter_file:
        '車両固有パラメータファイルのダウンロード$t(api.status.{{status}})',
      get_vehicles: '車両情報の取得$t(api.status.{{status}})',
      get_web_auto_application_environment:
        '走行環境のオプション情報取得$t(api.status.{{status}})',
      connect_websocket: 'WebSocketへの接続$t(api.status.{{status}})',
      patch_environment: {
        area_map_version:
          'エリアマップバージョンの変更$t(api.status.{{status}})',
        name: '走行環境名の変更$t(api.status.{{status}})',
        description: '走行環境説明の変更$t(api.status.{{status}})',
      },
      patch_vehicles: {
        driving_environment: '走行環境の変更$t(api.status.{{status}})',
        acceptable_order: '配車指示受付の変更$t(api.status.{{status}})',
        can_start: '発車指示受付の変更$t(api.status.{{status}})',
        vehicle_name: '車両名の変更$t(api.status.{{status}})',
        initial_position: '初期位置の変更$t(api.status.{{status}})',
        usage: '利用用途の設定$t(api.status.{{status}})',
        in_vehicle_app_federation:
          '車両からのAPI利用の設定$t(api.status.{{status}})',
      },
      patch_schedule: {
        tags: 'サービスタイプの変更$t(api.status.{{status}})',
      },
      patch_web_auto_application_environment: {
        enable_battery_alarm: 'バッテリー通知の変更$t(api.status.{{status}})',
        battery_alarm_threshold:
          'バッテリー通知の閾値変更$t(api.status.{{status}})',
        enable_weather_forecast_notification:
          '降水量通知の変更$t(api.status.{{status}})',
        weather_forecast_after_hours_threshold:
          '時間の閾値変更$t(api.status.{{status}})',
        weather_forecast_precipitation_threshold:
          '降水量の閾値変更$t(api.status.{{status}})',
        enable_stuck_alarm: '立ち往生通知の変更$t(api.status.{{status}})',
        stuck_alarm_threshold:
          '立ち往生判定分数の変更$t(api.status.{{status}})',
        error_alert_condition: 'エラー通知設定の変更$t(api.status.{{status}})',
        enable_telemetry_arrival_error_alarm:
          '車両のネットワーク警告通知の変更$t(api.status.{{status}})',
      },
      patch_vehicle_parameters: {
        description: '車両固有パラメータ説明の変更$t(api.status.{{status}})',
      },
      patch_calibration_parameter_settings: {
        enable_application_to_autoware_status:
          'Autoware への適用ステータス利用の設定変更$t(api.status.{{status}})',
      },
      post_deploy_edge: 'Web.Auto Agentの展開受付$t(api.status.{{status}})',
      post_approve_calibration_parameter_candidates:
        '新バージョンの生成と車両への展開受付$t(api.status.{{status}})',
      post_import_calibration_parameter_candidates:
        '候補パラメータの取得受付$t(api.status.{{status}})',
      post_create_environment: '走行環境の作成$t(api.status.{{status}})',
      post_editable_calibration_parameter_configs:
        'パラメータの編集$t(api.status.{{status}})',
      post_firmware_rollback:
        'Firmwareのロールバック受付$t(api.status.{{status}})',
      post_fsim_vehicle_launch: 'FSimの起動$t(api.status.{{status}})',
      post_fsim_vehicle_shutdown: 'FSimの停止$t(api.status.{{status}})',
      post_generate_lanelets_center_line_points:
        'レーン情報の生成$t(api.status.{{status}})',
      post_maintenance_schedule:
        '休止スケジュールの登録$t(api.status.{{status}})',
      post_maps_update: '地図の更新受付$t(api.status.{{status}})',
      post_restore_area_map_version:
        'エリアマップバージョンの復元指示$t(api.status.{{status}})',
      post_schedule_cancel: {
        successfully_check_page:
          'スケジュールキャンセル処理を開始しました\n最新の状態はスケジュール一覧ページで確認できます',
        successfully: 'スケジュールキャンセル処理を開始しました',
        failed: 'スケジュールキャンセル指示$t(api.status.failed)',
      },
      post_schedule_register: 'スケジュール登録$t(api.status.{{status}})',
      post_software_update: 'ソフトウェアの更新受付$t(api.status.{{status}})',
      post_task_complete: '手動運転の終了指示$t(api.status.{{status}})',
      post_task_skip: {
        successfully: 'タスクスキップのリクエスト$t(api.status.successfully)',
        failed: 'タスクスキップのリクエスト$t(api.status.failed)',
      },
      post_task_retry: {
        successfully: 'タスクリトライのリクエスト$t(api.status.successfully)',
        failed: 'タスクリトライのリクエスト$t(api.status.failed)',
      },
      post_time_tables: '運行ダイヤ設定の登録$t(api.status.{{status}})',
      post_unsafe_reset: {
        all: {
          successfully: 'スケジュールリセット処理を開始しました\n',
          failed: 'スケジュールリセット指示$t(api.status.failed)',
        },
        vehicle: {
          successfully: '{{name}}のスケジュールのリセット処理を開始しました\n',
          failed: '{{name}}のスケジュールリセット指示$t(api.status.failed)',
        },
        check_schedule_list_page:
          '最新の状態はスケジュール一覧ページで確認できます',
      },
      post_vehicle_door_open: '車両のドアを開く指示$t(api.status.{{status}})',
      post_vehicle_door_close:
        '車両のドアを閉じる指示$t(api.status.{{status}})',
      post_vehicle_emergency_stop: '緊急停止指示$t(api.status.{{status}})',
      post_vehicle_register: '車両登録$t(api.status.{{status}})',
      post_vehicle_resume: '運転再開指示$t(api.status.{{status}})',
      post_vehicle_snooze_stuck_alarm:
        '立ち往生通知の一時停止$t(api.status.{{status}})',
      post_vehicle_start: '発車指示$t(api.status.{{status}})',
      post_vehicle_suspend: '一時停止指示$t(api.status.{{status}})',
      post_vehicle_parameter_download_url:
        'ダウンロードURLの取得$t(api.status.{{status}})',
      post_vehicle_parameter_upload_url:
        'アップロードURLの取得$t(api.status.{{status}})',
      post_vehicle_parameter_deploy:
        'パラメータの展開$t(api.status.{{status}})',
      post_vehicle_update_autoware_access_mode:
        'Autowareアクセスモードの変更$t(api.status.{{status}})',
      post_vehicle_update_remote_control_status:
        '遠隔操作の{{action}}指示$t(api.status.{{status}})',
      post_vehicle_update_visualization_status:
        'Visualizationの{{action}}指示$t(api.status.{{status}})',
      post_vehicle_update_rosbag_save_mode:
        'Bag保存モードの変更指示$t(api.status.{{status}})',
      post_lane_unlock: 'レーンロック解除$t(api.status.{{status}})',
      post_reissue_certificate: '証明書の再発行$t(api.status.{{status}})',
      put_vehicle_parameter_file_upload:
        'パラメータの登録$t(api.status.{{status}})',
      // 通行禁止区域設定
      no_entry_zone_setting: {
        get: '通行禁止区域設定の取得$t(api.status.{{status}})',
        create: '通行禁止区域設定の作成$t(api.status.{{status}})',
        delete: '通行禁止区域設定の削除$t(api.status.{{status}})',
        update: '通行禁止区域設定の更新$t(api.status.{{status}})',
        apply: {
          successfully: '通行禁止区域設定の適用を開始しました',
          failed: '通行禁止区域設定の適用開始に失敗しました',
        },
        revoke: '通行禁止区域設定の解除$t(api.status.{{status}})',
      },
      // 通行禁止区域
      no_entry_zone: {
        create: '通行禁止区域の作成$t(api.status.{{status}})',
        delete: '通行禁止区域の削除$t(api.status.{{status}})',
        update: '通行禁止区域の更新$t(api.status.{{status}})',
      },
    },
    maintenance: {
      get_maintenance_events: 'メンテナンス情報の取得$t(api.status.{{status}})',
    },
  },
  dialog: {
    auth_error: {
      title: '認可エラー',
      message:
        '認可トークン取得時にエラーが発生しました<br>ページをリロードしてください',
    },
    dynamically_imported_module_error: {
      title: 'ページロードエラー',
      message:
        '古いファイルを読み込もうとしています。<br>新しいバージョンがリリースされているためページをリロードしてください。<br>続けてこのエラーが出る場合、ブラウザのキャッシュを削除して再度開いてください。',
    },
    websocket_error: {
      title: 'WebSocket接続エラー',
      message:
        'WebSocketの接続でエラーが発生しました<br>ご利用されている端末がネットワークに接続されていることを確認し、ページをリロードしてください',
    },
    other_error: {
      title: 'エラーが発生しました',
    },
    driving_environment: {
      create: {
        label: {
          name: '走行環境名',
          description: '走行環境説明',
        },
        attention: {
          empty_name: '走行環境名が入力されていません',
          long_name: '走行環境名が長すぎます',
          only_space: 'スペースのみ入力されています',
          long_description: '走行環境説明が長すぎます',
        },
      },
      edit: {
        title: '走行環境情報編集',
      },
      stuck_alarm_config: {
        switch: {
          message: '<strong>{{state}}</strong>に変更します',
          attention: '変更を反映するには車両を再起動する必要があります',
        },
        threshold: {
          title: '立ち往生判定分数設定',
          message: '立ち往生判定分数を<strong>{{count}}</strong>分に変更します',
        },
      },
      battery_alarm_config: {
        switch: {
          message: '<strong>{{state}}</strong>に変更します',
        },
        threshold: {
          title: 'バッテリー通知閾値設定',
          message: '<strong>{{count}}</strong>%に変更します',
        },
      },
      weather_alarm_config: {
        switch: {
          message: '<strong>{{state}}</strong>に変更します',
        },
        threshold: {
          hours: {
            title: '時間設定',
            message_one: '<strong>{{count}}</strong>時間後に変更します',
            message_other: '<strong>{{count}}</strong>時間後に変更します',
          },
          precipitation: {
            title: '降水量設定',
            message: '<strong>{{count}}</strong>mmに変更します',
          },
        },
      },
      create_lane_data: {
        title: 'レーン情報作成',
        message: 'レーン情報を作成します',
      },
      change_area_map_version: {
        attention: {
          initial_position: '初期位置が設定されている車両は解除されます',
          no_entry_zone_setting:
            '現在のエリアマップバージョンには通行禁止区域設定が適用されています\n設定はエリアマップバージョン毎に保持されるため確認した上で変更してください',
        },
      },
      delete: {
        message: '環境を削除すると過去の走行データ等は閲覧できなくなります',
      },
      no_entry_zone_setting: {
        apply: {
          title: '設定適用',
          message: '現在のエリアマップバージョンに設定を適用します',
          alert:
            '適用には数分かかります。\nすでに適用済みの設定は解除されます。',
        },
        revoke: {
          title: '設定解除',
          message: '現在のエリアマップバージョンから設定を解除します',
        },
        save: {
          title: '設定保存',
          message: '通行禁止区域設定を保存します',
          alert:
            '設定を適用させるには、保存後に設定詳細ページより適用させてください',
        },
        update: {
          title: '設定更新',
          message: '通行禁止区域設定の名称と説明文を更新します',
        },
        delete: {
          title: '設定削除',
          message: 'この通行禁止区域設定を削除します',
        },
      },
      no_entry_zone: {
        add: {
          title: '通行禁止区域追加',
          message: '通行禁止区域を追加します',
        },
        update: {
          title: '通行禁止区域更新',
          message: '通行禁止区域を更新します',
        },
        delete: {
          title: '通行禁止区域削除',
          message: 'この通行禁止区域を削除します',
        },
      },
    },
    vehicle: {
      vehicle_name: {
        message: '「{{name}}」の車両名を変更します',
      },
      delete: {
        message: '「{{name}}」を削除します',
        caption:
          '終了していないスケジュールがある場合削除されません。<br>車両が未接続、もしくは電源オフのときのみ削除できます。',
      },
      departure: {
        message: '発車指示を出します',
      },
      unlock: {
        message: '走行環境内の全車両のレーンロックを解除します',
        caption:
          '他の走行環境で同じエリアマップを利用している場合、そちらのレーンロックも解除されます',
      },
      emergency_stop: {
        message: '緊急停止指示を出します',
      },
      emergency_stop_all: {
        title: '全車両緊急停止',
        message: '全車両に緊急停止指示を出します',
      },
      door_open: {
        message: '車両のドアを開きます',
      },
      door_close: {
        message: '車両のドアを閉じます',
      },
      usage: {
        message: '',
      },
      change_driving_environment: {
        message: '初期位置が設定されている場合は解除されます',
        not_changeable: '変更可能な走行環境がありません',
      },
      map_update: {
        message: '「{{name}}」の地図を更新します',
        edge_no_deployments_message:
          'Web.Auto Agentの更新後、地図の更新を行なってください',
      },
      app_update: {
        message: '「{{name}}」の{{label}}を更新します',
        caption: {
          do_not_drive:
            '更新が完了するまで車両の走行を行わないようにしてください',
          individual_parameter:
            'ソフトウェア更新により、車両に展開しているパラメータが削除されます\nソフトウェア更新完了後に、再度パラメータを車両に展開して下さい',
        },
        latest_firmware: '現在のFirmwareは最新です',
        catalog: {
          service_vehicle: 'サービス車両',
          bench_vehicle: 'ベンチ車両',
        },
        force_deploy: 'Web.Auto Agent 強制展開',
      },
      certificate: {
        title: '車両証明書',
        delete: '証明書削除',
        reissue: '証明書再発行',
        delete_message: '「{{name}}」の証明書を削除します',
        delete_caption:
          '証明書を削除すると車両が利用できなくなります。<br>再び車両を利用する場合は証明書を再発行後、証明書の入れ替えを行ってください。',
        reissue_message: '「{{name}}」の証明書を再発行します',
        reissue_caption:
          '証明書は登録メールアドレスへ送付されます。<br>再び車両を利用する場合は証明書の入れ替え後、Web.Auto Agentの強制展開を行ってください。',
        remarks: '証明書を再発行するには、既存の証明書の削除が必要です',
        email: '登録メールアドレス：{{email}}',
      },
      initial_position: {
        register: {
          message: '「{{name}}」を初期位置として登録します',
        },
        cancel: {
          message: '設定されている初期位置を解除します',
        },
      },
      parameter: {
        register: {
          message: 'アップロードされたファイルを圧縮して登録します',
        },
        download: {
          title: 'ダウンロード',
          message: 'パラメータファイルをダウンロードします',
        },
        deploy: {
          title: '車両に展開',
          message: 'パラメータを車両に展開します',
        },
        edit: {
          title: '車両パラメータ説明',
          message: '車両パラメータ説明を編集します',
          long_description: '車両パラメータ説明が長すぎます',
        },
        editor: {
          message: '編集したパラメータを登録します',
        },
        setting: {
          enable_application_to_autoware_status: {
            title: 'Autoware への適用ステータスの利用設定変更',
            message: '「{{value}}」に変更します',
          },
        },
        detail: {
          title: 'パラメータ詳細',
          general: '一般情報',
          files: 'ファイル一覧',
          base: '登録元のバージョンID',
          firmware: '登録時のFirmwareリリースID',
          version_id: 'バージョンID',
          edited: '編集したパラメータ',
          output_file_name: '出力ファイル名',
          api_error_message: '詳細情報の取得に失敗しました',
        },
      },
      remote_control: {
        title: '遠隔操作設定変更',
      },
      visualization: {
        title: 'Visualization設定変更',
        message:
          '現在開発中の機能です\n使用可能ですが、CPUに大きく負荷をかける場合があるのでご注意ください',
      },
      agent_restart: {
        message: 'Web.Auto Agent の再起動が必要です',
      },
      bag_save_mode: {
        title: 'Bag保存モード変更',
      },
      autoware_access_mode: {
        title: 'Autowareへのアクセスモード変更',
        message: 'Web.Auto Agent の再起動が必要です',
        alert: '安全な環境で動作検証をしてからお使いください。',
        radio: {
          rosbridge: 'rosbridgeアクセスモード',
          rclpy: '直接アクセスモード',
        },
      },
    },
    schedule: {
      register: {
        schedule: {
          message: 'スケジュールを登録します',
        },
        maintenance: {
          message: '休止スケジュールを登録します',
        },
        time_table: {
          message: '{{day}}の運行ダイヤ設定を登録します',
        },
        no_entry_zone_alert: {
          message:
            '通行禁止区域設定が適用されているためルート検索の表示と実際の走行ルートが異なる場合があります',
        },
      },
      cancel: {
        schedule: {
          message: 'スケジュールをキャンセルします',
        },
        time_table: {
          message: '{{day}}の運行ダイヤ設定を削除します',
          related: '関連する実施予定・実施中のスケジュールをキャンセル',
        },
      },
      reset: {
        message:
          '車両のスケジュールを強制的にリセットします。スケジュールをリセットした後は、必ず車両を再起動してください。 再起動が正しく行われない場合、車両が予期せぬ動作をする可能性がある点をご注意の上、リセットを実行してください。',
        count: '{{count}}台の車両のスケジュールをリセットします',
        count_plural: '{{count}}台の車両のスケジュールをリセットします',
      },
      reset_route: {
        message: '編集した経路をリセットします',
      },
      manual_drive: {
        register: '移動時間を入力してください',
        cancel: '手動運転から自動運転に変更します',
      },
      service_type: {
        change: '{{service_type}}に変更します',
      },
      task: {
        complete: {
          title: '手動運転終了',
          message: '「{{name}}」の手動運転を終了します',
        },
        skip: {
          title: 'タスクスキップ',
          message: 'このタスクをスキップします',
        },
        retry: {
          title: 'ルート再適用',
          message: 'このルートを再度適用します',
        },
      },
    },
    driving_memo: {
      save: {
        message: '入力した内容で保存します',
      },
    },
    analysis: {
      common: {
        display: {
          label: '表示件数',
          description: '地図上のマーカーと一覧の表示件数を設定できます',
          switch: '表示件数を設定する',
        },
      },
      error: {
        filter: 'ノードによる絞り込み',
        target_value: 'エラー目標値',
      },
      override: {
        filter: '絞り込み',
      },
    },
    vehicle_condition: {
      uptime_reset: {
        message: 'システム稼働時間が0になります',
      },
    },
  },
  // 車両
  vehicle: {
    name: '車両名',
    error: 'エラー',
    warning: '警告',
    override: '介入',
    product_name: 'プロダクト名',
    vehicle: '車両',
    vehicles: '$t(vehicle.vehicle)一覧',
    vehicle_name: '$t(vehicle.vehicle)名',
    vehicle_login_id: '$t(vehicle.vehicle)ログインID',
    stuck: '立ち往生',
    manual_override: '手動Override',
    ndt_error: '自己位置推定エラー',
    camera: 'カメラ',
    manual_driving: '手動運転',
    foa: {
      foa: 'MOT/FOA',
      signal_overwrite: '信号上書き',
      speed_operation: '徐行・停止',
      lane_change: 'レーンチェンジ',
    },
    bag_file: 'Bagファイル',
    syslog: 'Syslog',
    tcpdump: 'tcpdump',
    coredump: 'coredump',
    telemetry: {
      name: 'テレメトリ',
      speed: 'スピード',
      status: {
        disconnected: '未接続',
        connected: '接続済み',
        initializing: '初期化中',
        task_acceptable: 'タスク待ち',
        ready: '発車指示待ち',
        driving: '走行中',
        pausing_for_stop_line: '一時停止線停止中',
        pausing_for_obstacle: '障害物停止中',
        suspending: '一時停止中',
        stopping: '停止中',
        abnormal: '異常',
        shutdown: '電源OFF',
        unknown: '状態不明',
      },
      drive_mode: {
        title: '運転状態',
        auto: '自動',
        manual: '手動',
      },
      battery: 'バッテリー',
      battery_not_available: '取得不可',
      diagnostic_notice: {
        level: {
          0: '正常',
          1: '警告',
          2: '異常',
          3: '通信不通',
        },
        hardware_id: 'ハードウェアID',
      },
      telemetry_arrival_error: 'ネットワーク警告',
    },
    type: {
      actual: '実車両',
      psim: {
        short: 'PSim',
        name: 'Planning Simulator',
      },
      fsim: {
        short: 'FSim',
        name: 'FMS Simulator',
      },
    },
    edge_deployment_time: 'Web.Auto Agent 更新完了時刻',
    firmware: {
      firmware_release_id: 'FirmwareリリースID',
      firmware_release_name: 'Firmwareバージョン',
      firmware_deployment_time: 'Firmware 更新完了時刻',
      firmware_deployment_ecu_status: {
        download_error_counts: 'ダウンロードエラー数',
        download_error_rate: 'ダウンロードエラー率',
        download_error_rate_unit: '回/ファイル',
        download_processed_file_size: 'ダウンロード済ファイルサイズ',
        elapsed_time: '経過時間',
        processed_file_size: '完了したファイルサイズ',
        processed_number_of_files: '完了したファイル数',
        release_name: 'Firmwareバージョン',
        total_number_of_files: '総ファイル数',
        transfer_rate: '転送速度',
        total_file_size: '総ファイルサイズ',
        remaining_time: '残り時間',
        remaining_time_status: {
          unavailable: '算出できません',
          in_preparation: '算出中',
          almost_done: 'まもなく完了します',
        },
      },
    },
    rosbag_save_mode_status: {
      label: 'Bag保存モード',
      value: {
        unknown: '$t(common.status.unknown)',
        operation: '運用',
        always_saving: '常時保存',
      },
      failure: '変更失敗',
    },
    departure: '発車',
    can_not_start: {
      message: '発車指示が不可に設定されているためFMSから発車指示はできません',
    },
    emergency_stop: '緊急停止',
    unlock: 'レーンロック解除',
    all: '全車両',
    door: 'ドア',
    catalog: {
      name: 'カタログ',
      catalog_id: '$t(vehicle.catalog.name)ID',
      catalog_name: '$t(vehicle.catalog.name)名',
    },
    category: 'カテゴリ',
    remote_control: {
      title: '遠隔操作',
      description: 'Autoware Driveの遠隔操作',
    },
    visualization: {
      title: 'Visualization',
      description: '遠隔監視画面に表示されるVisualization',
    },
    autoware_access_mode: {
      label: 'Autowareへのアクセスモード',
    },
    creation_status: {
      creating: '作成中',
      creation_failed: '作成失敗',
      deleting: '削除中',
    },
  },
  // Media
  media: {
    camera: {
      not_connected: 'カメラが接続されていません',
    },
  },
  // スケジュール
  schedule: {
    schedule_id: '$t(common.fms.schedule) ID',
    type: {
      basic: '片道',
      'one-way': '片道',
      loop: '巡回',
      cyclic: '巡回',
      time_table: '運行ダイヤ',
      maintenance: '休止',
    },
    status: {
      todo: '実施予定',
      doing: '実施中',
      done: '完了',
      canceling: '取消中',
      canceled: '取消済み',
      aborting: '中断中',
      aborted: '中断済み',
      disabled: '無効',
      unknown: '状態不明',
    },
    plan_time: '予定走行時刻',
    plan_start_time: '予定開始時刻',
    plan_end_time: '予定終了時刻',
    actual_time: '実走行時刻',
    actual_start_time: '実開始時刻',
    actual_end_time: '実終了時刻',
    delay_time: '遅延時間',
    duration: '所要時間',
    start_end_time: '開始終了時刻',
    created_at: '作成日時',
    task: {
      type: {
        maintenance: '休止',
        move: '移動',
      },
      status: {
        aborting: '中断中',
        aborted: '中断済み',
        doing: '実施中',
        done: '完了',
        todo: '実施予定',
        disabled: '無効',
        unknown: '状態不明',
      },
    },
    tag: {
      service_type: {
        service: 'サービス走行',
        test: 'テスト走行',
      },
      route_name: 'ルート名',
    },
    via_point: {
      label: '経由地',
    },
  },
  maintenance: {
    type: {
      scheduled: '定期メンテナンス',
      unplanned: '臨時メンテナンス',
    },
    status: {
      'not started': '開始前',
      'in progress': '進行中',
      completed: '完了',
      canceled: 'キャンセル',
    },
    incident_level: {
      label: '影響度',
      critical: '影響あり',
      high: '$t(maintenance.incident_level.critical)',
      moderate: '一部影響あり',
      low: '$t(maintenance.incident_level.moderate)',
      informational: '影響なし',
    },
    target_services: '影響のある\nサービス',
    last_updated_time: '最終更新日時',
  },
  page: {
    top: 'トップ',
    vehicle_detail: '車両詳細',
    vehicle_register: '車両登録',
    vehicle_management: '車両一覧',
    vehicle_condition: '車両状態管理',
    schedule_register: 'スケジュール登録',
    driving_history: '走行履歴',
    operation_report: '運行レポート',
    maintenance_schedule: '休止スケジュール',
    schedule_check: 'スケジュール一覧',
    error_detail: 'エラー一覧',
    initial_position: '初期位置設定',
    driving_environment: '走行環境',
    driving_memo: '走行メモ',
    plan_actual_variance: '予実解析',
    analysis: '分析',
    settings: '設定',
  },
  header: {
    switch_environment: '環境切替',
  },
  // プロジェクト・走行環境選択 ダイアログ
  project_environment_dialog: {
    title: 'プロジェクトと走行環境の選択',
    create_environment: '走行環境作成',
  },
  // トップページ
  top: {
    active_vehicles: '稼働車両',
    error_state_vehicles: 'エラー車両',
    inactive_vehicles: '未稼働車両',
    detail: '車両詳細',
    all_vehicles_emergency_stop_button: '全車両緊急停止',
    vehicle_info: '車両情報',
    applied_no_entry_zone_setting: {
      alert: '通行禁止区域があります',
    },
  },
  // 車両詳細
  vehicle_detail: {
    next_schedule: {
      title: '次のスケジュール',
    },
    camera: {
      label: '遠隔監視システム',
      view_button: '起動',
      available_camera: 'カメラ有り',
      not_available_camera: 'カメラ無し',
    },
    health_check: {
      title: 'ヘルスチェック',
    },
    schedule_detail: {
      is_failed_bypass: {
        has_partial_routes:
          '経由地が設定されていますが通行禁止区域により目的地まで走行できません',
        no_has_partial_routes: '通行禁止区域により目的地まで走行できません',
      },
      task: {
        skip_retry: {
          disabled_message: {
            schedule_canceling: 'スケジュールキャンセル中は操作できません',
            schedule_aborting: 'スケジュール中断中は操作できません',
          },
        },
        skip: {
          disabled_message: {
            not_manual_drive_status: '手動運転状態の場合のみスキップ可能です',
          },
          status: {
            succeeded: 'ルート適用完了',
            in_progress: 'ルート適用中',
            failed: 'ルートの適用に失敗しました',
          },
        },
        retry: {
          button_label: '再度適用する',
          disabled_message: {
            not_manual_drive_status: '手動運転状態の場合のみ再度適用が可能です',
          },
        },
      },
    },
  },
  // 車両登録ページ
  vehicle_register: {
    cert: '証明書送付先',
    input_title: '車両情報入力',
    confirm_title: '車両情報確認',
    vehicle_name: {
      title: '車両名（1文字以上128文字以下）',
    },
    catalog_select: {
      title: 'カタログ選択',
      unselect: '未選択',
    },
    usage: {
      title: '利用用途',
      actual: '実車両',
      simulator: 'シミュレーション車両',
    },
    in_vehicle_app_federation: {
      title: '車両からのAPI利用',
      possible: '有り',
      impossible: '無し',
      caption:
        '車載アプリ連携機能が必要ない車両はセキュリティ的には有効にしない事を推奨しています。車載のHTTPサーバーに外部からインバウンドなHTTPアクセスが許可されてしまうようなネットワーク構成にはしないでください。',
    },
    vehicle_login_id: {
      create: 'Vehicle login IDを作成する',
      title: 'Vehicle login ID (半角文字、4文字以上 128文字以下)',
      password: 'Password (8文字以上)',
      password_confirm: 'Password 確認',
    },
    dialog: {
      message: '設定した内容で「{{vehicleName}}」を登録します',
    },
  },
  // 車両状態確認ページ
  vehicle_management: {
    schedule_reset: '選択車両スケジュールリセット',
    tabs: {
      other_driving_environments: 'その他の走行環境',
    },
    deleted_certificate: '証明書が削除されています',
    connection: {
      title: '接続状態',
      connect: '接続',
      disconnect: '未接続',
    },
    acceptable_order: {
      title: '配車指示',
      all: '配車可',
      none: '配車不可',
      dedicated: '指定車両のみ配車可',
    },
    can_start: {
      title: '発車指示',
      enabled: '発車可',
      disabled: '発車不可',
    },
    map_update: {
      title: '車両地図更新',
      tooltip: '地図が最新ではない場合<br>発車など一部機能が利用できません',
    },
    app_update: {
      title: 'ソフトウェア更新',
      failure_reason: '失敗理由',
      firmware: 'Firmware',
      web_auto_agent: 'Web.Auto Agent',
      ecu_update_status: 'ECU更新状態',
    },
    adapi_version: {
      road_shoulder: '停車位置変更に対応',
    },
    vehicle_info_options: {
      title: '情報・オプション',
      info: {
        title: '情報',
        all_copy: 'すべてコピー',
        page_url: '車両情報URL',
      },
      options: {
        title: 'オプション',
        environment_change: '走行環境変更',
        delete_vehicle: '車両削除',
        in_vehicle_app_federation: '車両からのAPI利用',
        disabled_autoware_access_mode_tooltip:
          'Greengrass v2 で Web.Auto Agent の環境構築を行った後に変更することができます',
      },
    },
    notification: {
      calibration_parameter_deployment: '車両固有パラメータの展開が必要です',
      calibration_parameter_is_tampered:
        '車両に展開したパラメータが展開後に改変されました',
      calibration_parameter_not_applied_to_autoware:
        '車両固有パラメータがAutowareに適用されていません',
      no_vehicle_id_found:
        '車両が見つかりません。\n削除されたか参照できない環境に移動されている、もしくは車両IDが間違っています。プロジェクト管理者に確認してください。',
    },
    vehicle_detail_page_link: '$t(page.vehicle_detail)ページ',
    vehicle_creation_failed_message: '車両を削除し、再度登録しなおしてください',
    certificate_reissue_message: {
      success: '証明書の再発行に成功しました',
      failure: '証明書の再発行に失敗しました',
      in_progress: '証明書を再発行中です',
    },
  },
  // スケジュール登録ページ
  schedule_register: {
    estimated_time: '予想所要時間',
    first_origin_position: {
      vehicle_position: '車両の現在位置',
      latest_basic_schedule_destination_position:
        '最後のスケジュールの目的地 ({{name}})',
    },
    time_table: {
      register: '{{day}}の運行ダイヤ設定$t(common.action.register)',
      remove: '{{day}}の運行ダイヤ設定$t(common.action.remove)',
      info: {
        registered: '登録済',
        editing: '編集中',
      },
    },
    schedule_type: 'スケジュールタイプ',
    service_type: 'サービスタイプ',
    attention: {
      autoware_not_running: 'Autowareが起動していません',
      schedule: {
        continuous_same_stop_point: '同じ停車地点が連続しています',
        only_one_stop_point: '停車地点が一つしか登録されていません',
        over_max_routes: 'ルート数を{{max_route_count}}以下に設定してください',
        loop_schedule_todo_or_doing:
          '巡回スケジュールが実施予定・実施中のため登録できません',
        maintenance_schedule_todo:
          '休止スケジュールが実施予定のため巡回スケジュールは登録できません',
        maintenance_schedule_doing:
          '休止スケジュールが実施中のため登録できません',
        schedule_canceling: 'スケジュールがキャンセル中のため登録できません',
        schedule_aborting: 'スケジュールが取消中のため登録できません',
        no_vehicle: 'スケジュール登録可能な車両がありません',
        invalid_start_time: '予定開始時刻のフォーマットが不正です',
      },
    },
    via_point: {
      disabled: {
        not_created_lanelet_tooltip:
          'エリアマップバージョンのレーン情報が未作成です。走行環境ページより作成してください。',
        manual_drive_tooltip: '手動運転登録を解除してください',
      },
      reset: '編集前にリセット',
      route_search_failed: 'ルート検索に失敗しました',
      edit_description: {
        base: '地図上の経由したいレーンを選択し、追加してください。\n最大{{length}}箇所追加できます。追加済みのレーンは再度追加できません。\n',
        no_entry_zone:
          '通行禁止レーンが重なる場合、地図の表示切り替えから非表示にすることができます。',
      },
    },
    basic: {
      manual_drive: {
        disabled: {
          via_point_tooltip: '経由地が設定されています',
        },
      },
    },
    ad_api: {
      unsupported_road_shoulder: {
        stop_point:
          '停車位置変更可能な停車地点ですが、選択されている車両の Autoware/AD API が未対応のため、停車位置が変更できない場合があります。',
        dialog:
          '経路内に停車位置変更可能な停車地点が含まれていますが、選択されている車両の Autoware/AD API が未対応のため、停車位置が変更できない場合があります。',
      },
    },
  },
  // スケジュール一覧ページ
  schedule_check: {
    caption_one: '全{{count}}件',
    caption_other: '全{{count}}件',
    not_found: 'スケジュールはありません',
    copy_schedule_id: 'IDをコピー',
  },
  // エラー一覧ページ
  error_detail: {
    data: {
      name: '発生場所名',
      start_time: '発生時刻',
      end_time: '終了時刻',
      hardware_id: 'ハードウェアID',
      level: 'レベル',
      message: '異常内容',
      diag_period: '発生期間',
      error_period: 'エラー発生期間',
    },
    condition: {
      emergency: '異常状態で発生したエラー',
      emergency_during_auto: '自動運転状態でのみ発生するすべてのエラー',
      emergency_stopped: '自動運転状態で緊急停止となったエラー',
    },
    create_driving_memo: 'エラーから走行メモを作成',
    copy_link_url: 'リンクURLをコピー',
    back_to_list: '一覧に戻る',
    error_type: {
      select_label: 'エラー表示条件',
      all: 'すべてのエラー',
    },
    load_value: {
      first: 'Valueを読み込む',
      more: '続きのValueを読み込む',
    },
  },
  // 初期位置設定ページ
  initial_position: {
    select: '初期位置',
  },
  // 走行メモページ
  driving_memo: {
    list: {
      occurrence_date_time: '発生日時',
    },
    category: {
      memo: 'メモ',
      near_miss_accident: 'ヒヤリハット',
      bug: '不具合',
      incident: 'インシデント',
    },
    prompt: 'ページ遷移します。入力中の情報は失われますがよろしいですか？',
    register: {
      to_continue_create_button: '保存して続けて作成',
      to_list_create_button: '保存して一覧へ',
      do_not_show_save_dialog: '保存時にダイアログを表示しない',
      simple_input: '簡易入力',
      invalid_format_occurrence_date: '発生日時のフォーマットが不正です',
    },
    detail: {
      subject: 'タイトル',
      surroundings: '周囲の状況',
      not_found: 'メモが見つかりません',
      cargo: {
        label: '運搬物',
        yes: '有り',
        no: '無し',
      },
      date: {
        label: '発生日',
      },
      time: {
        label: '発生時刻',
        buttons: {
          set_current_time: '現在日時入力',
          plus_seconds: '+{{second}}秒',
          minus_seconds: '-{{second}}秒',
        },
      },
      weather: {
        label: '天気',
        type: {
          sunny: '晴れ',
          cloudy: '曇り',
          rainy: '雨',
          snowy: '雪',
          etc: 'その他（強風・雷など）',
        },
      },
      history: {
        title: 'Bag・走行情報',
        slider_title: '発生時刻後{{count}}分を取得',
      },
    },
    weather: {
      cant_get: '今日の天気は取得できません。翌日から表示されます。',
      select_caption:
        '天気観測情報の取得に失敗しました。下から選択して下さい。',
    },
    attention: {
      not_probe_data_generated:
        '走行データが未整備のため車両位置は表示されません',
    },
  },
  // 走行環境ページ
  driving_environment: {
    error_notification_setting: {
      title: 'エラー通知設定',
      condition: {
        emergency: '$t(error_detail.condition.emergency)を通知',
        emergency_during_auto:
          '$t(error_detail.condition.emergency_during_auto)を通知',
        emergency_stopped:
          '$t(error_detail.condition.emergency_stopped)のみを通知',
      },
    },
    stuck_alarm_config: {
      title: '立ち往生車両通知設定',
      none: '立ち往生通知の設定が取得できません',
      threshold: '判定分数 (1 - 30分)',
    },
    battery_alarm_config: {
      title: 'バッテリー通知設定',
      threshold: '閾値 (0 - 99%)',
    },
    weather_alarm_config: {
      title: '天気予報通知設定',
      threshold: {
        time: 'X時間後 (1 - 10時間)',
        precipitation: '降水量Xmm以上 (0.5 - 30mm)',
      },
    },
    network_alarm_config: {
      title: '車両のネットワーク警告通知設定',
    },
    area_map_info: {
      title: 'エリアマップ',
    },
    current_area_map_version: {
      title: '現在のエリアマップバージョン',
      disable_no_entry_zone_setting:
        'レーン情報が未作成のため通行禁止区域設定を確認できません',
    },
    area_map_version_history: {
      title: 'エリアマップバージョン履歴',
      caption_list: {
        quota: {
          description:
            'リリースステータスが完了となっている最新{{quota}}件のバージョンは自動アーカイブから保護されます',
          tooltip: {
            title: '自動アーカイブについて',
            description:
              'FMSではリソース節約のため、使われていない古いバージョンのデータの整理が自動で行われます。\nデータの整理が行われたバージョンはアーカイブ済みに移行します。\n再度そのバージョンを使用したい場合はバージョンの復元を行ってください。',
          },
        },
        lane_info:
          'レーン情報を作成するとスケジュール登録ページで経路間に経由地を指定することができます',
      },
      tabs: {
        available: '利用可能',
        archived: 'アーカイブ済み',
      },
      table: {
        release_status: 'リリースステータス',
        lanelets_center_line_points_status: 'レーン情報ステータス',
        current_version: '現在のバージョン',
        change_version: 'このバージョンに変更',
        restore_version: 'このバージョンを復元',
        create_lane_data: '作成',
      },
      tooltip: {
        disable_change_area_map_version:
          'このバージョンはFMSではご利用できません',
        disable_restoring:
          '復元中のエリアマップバージョンがあるため実行できません',
        disable_create_lane: 'このバージョンは作成できません',
      },
    },
    delete: {
      title: '走行環境削除',
    },
    no_entry_zone_setting: {
      title: '通行禁止区域設定',
      list: {
        name: '設定名',
        count: '登録設定数',
        zone_count: '通行禁止区域数',
        over_length: 'これ以上作成できません。\n不要な設定を削除してください。',
        has_not_lanelets_info: 'エリアマップバージョンのレーン情報が未作成です',
        action: {
          add: '新規作成',
          copy_and_create_new: 'コピーして新規作成',
        },
        disable_delete_message: {
          current_area_map_version_applied:
            '現在のエリアマップバージョンに適用されているため削除できません',
          others_area_map_version_applied:
            '別のエリアマップバージョンに適用されているため削除できません',
          applying: '適用中のため削除できません',
        },
        message: {
          only_one_application: '通行禁止区域設定は1つだけ適用できます',
          alert_apply:
            '適用されている通行禁止区域設定はありません。確認ボタンから設定詳細画面へ遷移して適用することができます。',
        },
      },
      status: {
        applying: '適用中',
        applied: '適用済み',
        application_failed: '適用失敗',
        not_apply: '未適用',
      },
      detail: {
        not_found: '通行禁止区域設定が存在しません',
        applied_message: 'この設定はエリアマップバージョンに適用されています',
        apply_disable: {
          invalid_lane:
            '存在しないレーンが設定されている通行禁止区域があるため適用できません',
          application_failed: '適用に失敗しているため適用できません',
        },
        action: {
          apply: '現在のエリアマップバージョンに適用',
          revoke: '現在のエリアマップバージョンから解除',
          update: '名称・説明を更新',
        },
      },
      form: {
        required: '入力してください',
        over_length: '{{count}}文字以内で入力してください',
      },
      no_entry_zone: {
        title: '通行禁止区域',
        list: {
          caption: '最大10件登録できます',
          not_addable: '未適用の設定のみ通行禁止区域は編集可能です',
          not_zero: '通行禁止区域をすべて削除することはできません',
          action: {
            add: '通行禁止区域作成',
          },
        },
        category: {
          label: 'カテゴリ',
          construction: '工事',
          others: 'その他',
        },
        time_type: {
          period: '定期',
          spot: '日時指定',
        },
        detail: {
          tabs: {
            form: 'フォーム',
            select_lane: 'レーン選択',
          },
          disable_edit: '編集時は変更できません',
          lane_list: {
            caption:
              '地図上の通行禁止にしたいレーンを選択し、追加してください。最大10箇所追加できます。',
            display_lane: 'レーン表示',
            not_exist: 'このレーンは存在しません',
          },
        },
      },
      map: {
        over_lanelets:
          '10レーンを超えるため追加できません。\n選択しなおしてください。',
        multiple_lanelets: '{{count}}レーン追加します.',
        currently_applied_no_entry_zone: '通行禁止中',
      },
    },
  },
  // 運行レポートページ
  operation_report: {
    select_date_error_message: '今月以前の月を指定してください',
    aggregation_caption: '走行情報は毎時集計されます',
    distance_per_error: '1エラーあたりの走行距離',
    distance_per_override: '1介入あたりの走行距離',
    graph: {
      daily_mileage: '日別の走行距離',
      daily_error_and_distance_per_error:
        '日別のエラー数と1エラーあたりの走行距離',
      daily_override_and_distance_per_override:
        '日別の介入理由数・内訳と1介入あたりの走行距離',
    },
  },
  // 走行履歴ページ
  driving_history: {
    based_on_allocate: '配車スケジュールベース一覧',
    driving_time_refine: '走行時間による絞り込み詳細',
    not_found: '走行履歴がありません',
    filter: {
      task_list: {
        only_task_contain_error_or_override_foa:
          'エラー or 手動Override or MOT/FOAを含むタスクのみ',
        only_error_task: 'エラーを含むタスクのみ',
        only_override_task: '手動Overrideを含むタスクのみ',
        only_foa_task: 'MOT/FOAを含むタスクのみ',
      },
      period: {
        only_bag_contain_error_or_override_foa:
          'エラー or 手動Override or MOT/FOAを含むBagのみ',
        only_error_bag: 'エラーを含むBagのみ',
        only_override_bag: '手動Overrideを含むBagのみ',
        only_foa_bag: 'MOT/FOAを含むBagのみ',
      },
    },
    attention: {
      not_probe_data_generated:
        '<strong>走行経路・エラー・介入情報</strong>は未整備です<br>時間を空けて再度確認してください',
      over_100_errors: '100件以上エラーがある場合マーカーは表示されません',
      over_100_foa: '100件以上MOT/FOA介入がある場合マーカーは表示されません',
      date: {
        minimum_duration_under: '期間は12時間以内になるように設定してください',
      },
      is_fsim: 'FSim 車両はエラー等の確認はできません',
    },
    probe_loading: '走行経路を読み込んでいます',
    error_list: {
      event: {
        tooltip: {
          shutdown_start: 'これはエラーではありません',
          shutdown_end:
            '$t(driving_history.error_list.event.tooltip.start_shutdown)',
          network_connected:
            'これはエラーではありませんが、ネットワーク切断中のエラーは取得されないことに注意してください',
          network_disconnected:
            '$t(driving_history.error_list.event.tooltip.network_connected)',
        },
      },
    },
    foa_list: {
      location_unknown: '位置情報が不明です',
    },
    file_list: {
      download_error: {
        download_error:
          '{{file_type}} ファイルのダウンロード中にエラーが発生しました。',
        file_not_found:
          '{{file_type}} ファイルのダウンロードに失敗しました。ファイルが見つかりません。',
        network_error:
          'ネットワークエラーにより {{file_type}} ファイルのダウンロードに失敗しました。',
      },
    },
  },
  // 予実解析ページ
  plan_actual_variance: {
    plan_actual_variance: '予実差',
    detail_window: {
      route_all_button: '全ルート',
      route_detail_button: 'ルート詳細',
      stuck_detail_button: '滞留詳細',
    },
    graph: {
      variance: '予実比',
      stuck: '立ち往生発生数',
      error: 'エラー発生数',
      ndt: '自己位置推定エラー発生数',
      driving: '走行回数',
      settings: {
        medium: '予実差中（%）',
        large: '予実差大（%）',
      },
    },
  },
  // 分析
  analysis: {
    graph: {
      mileage: '$t(common.general.mileage) (km)',
    },
    error: {
      graph: {
        type_ratio: '分類 (%)',
        type_count: '分類 (回)',
        count: 'エラー発生回数',
        number_of_errors_per_km: '1kmあたりのエラー数',
        number_of_mileage_per_error: '1エラーあたりの走行距離 (km)',
      },
      list: 'エラー発生期間',
      csv: {
        button: {
          generate: '{{date}}のCSV生成',
          download: '{{date}}のCSVダウンロード',
        },
        tooltip: {
          no_filter: 'フィルタリングは適用されません',
          overtime: '期間を1日にしてください',
        },
      },
    },
    override: {
      graph: {
        type_ratio: '分類 (%)',
        type_count: '分類 (回)',
        count: 'Override発生回数',
        number_of_override_per_km: '1kmあたりのOverride数',
      },
      list: 'Override一覧',
    },
    scan_matching_score: {
      label: '自己位置推定マッチングスコア',
      score_type: 'スコアタイプ',
      graph: {
        average: '自己位置推定マッチングスコア / 1日平均',
      },
    },
  },
  // 地図メニュー
  map_menu: {
    title: '表示切り替え',
    stop_point_names: '停車地点名',
    no_entry_zones: {
      title:
        '$t(driving_environment.no_entry_zone_setting.no_entry_zone.title)',
      all: 'すべて表示',
    },
    lanelets_center_points: 'レーン',
  },
  // 設定
  settings: {
    language: {
      title: '言語',
      reload_caption: '言語を変更するとページリロードされます',
    },
    alert: {
      title: '通知',
      auto_close: {
        title: '自動で通知を閉じる',
        message: '約10秒後に自動で閉じられます',
      },
      sound: {
        title: 'アラート音',
      },
    },
    map: {
      title: '地図表示',
      check: {
        vehicle_center: '車両を常に地図の中心にする',
        display_current_route: '地図上に現在のルートを表示する',
      },
    },
  },
  // 遠隔監視画面
  remote: {
    cameras: {
      no_scope: 'カメラを見る権限がありません',
      no_camera: 'カメラが見つかりません',
    },
    call: {
      state: {
        calling: '呼び出し中',
        talking: '通話中',
      },
    },
    operation: {
      view: {
        label: '画面切り替え',
        camera_and_call: 'カメラ & 通話',
        map_and_viz: '地図 & Viz',
        display_viz: 'Vizを表示',
      },
    },
  },
  // 車両状態管理
  vehicle_condition: {
    vehicle_not_found: '車両が見つかりません',
    list: {
      condition: 'システム稼働時間 & 走行距離',
      individual_parameter: '車両固有パラメータ',
    },
    parameter: {
      status: {
        is_tampered: {
          label: '車両側で改変有り',
          caption:
            '車両に展開したパラメータが展開後に変更され、展開時のものと異なっています。再度車両にパラメータを展開して下さい。',
        },
        application_to_autoware_status: {
          applied: '現在のバージョンは Autoware へ適用されています。',
          not_applied:
            '現在のバージョンは Autoware へ適用されていません。\n車両を再起動してください。',
        },
      },
      list: {
        title: '登録一覧',
        disabled_deploy: '車両がFMSに接続されていません',
        version: {
          current: '現在のバージョン',
          deployment_candidate: '展開候補バージョン',
          last_deployed: '最後に展開したバージョン',
        },
      },
      register: {
        title: 'パラメータ登録',
        description: {
          caption: '最大255文字',
          error: '半角文字以外の文字が入力されています',
        },
        select_directory: {
          label: 'ディレクトリ選択',
          hide_hidden_files: '不可視ファイルを隠す',
          file_not_found: 'ファイルが見つかりません',
        },
      },
      suggestion: {
        title: '候補パラメータ適用',
        item: {
          target: {
            title: 'キャリブレーション対象',
            calibration_status: 'キャリブレーションステータス',
            candidates_status: '候補パラメータステータス',
          },
          status: {
            title: '$t(common.status.status)',
            label: {
              normal: '正常',
              nothing: '候補パラメータ未取得',
              calibration_required: '要パラメータ更新',
              unavailable: '候補パラメータ利用不可',
              calibration_required_after_parameter_deployed:
                'パラメータ展開後に未反映',
              importing: '候補パラメータ取得中',
              import_failure: '候補パラメータ取得失敗',
              imported: '候補パラメータ取得完了',
              waiting_for_approval: '新バージョン生成中',
              approved: '新バージョン生成完了',
              approval_failure: '新バージョン生成失敗',
              undefined: '不明',
            },
            restart: '車両の再起動が必要です',
            failure: {
              title: '失敗理由',
            },
          },
        },
        action: {
          import: '候補パラメータ取得',
          imported: '候補パラメータ再取得',
          confirm: '候補パラメータ確認',
          dialog: {
            deploy: '新バージョンを生成し車両に展開する',
          },
          attention:
            'パラメータを反映するため展開完了後に、車両を再起動して下さい',
        },
      },
      editor: {
        title: 'パラメータ編集',
        not_editable: '編集可能なパラメータはありません',
        reload: '値が更新されています、再読み込みを実行して下さい',
        expanded_capacity: {
          error_message: {
            empty: '入力してください',
            under_min: '下限を超えています',
            over_max: '上限を超えています',
            not_number: '数値を入力してください',
            decimal: '小数点第{{count}}位までで入力してください',
          },
        },
      },
      setting: {
        enable_application_to_autoware_status: {
          label: 'Autowareへの適用ステータスの有効化',
          tooltip:
            '車両に展開したパラメータのAutowareへの適用ステータスの通知、Autowareへの適用ステータスに応じたルート配信や発車の禁止を行います\n詳細は<docs>こちら</docs>をご確認ください',
          on: '有効',
          off: '無効',
          unsupported:
            'Autowareへの適用ステータスがサポートされていないため変更できません',
        },
      },
    },
    condition: {
      caption: 'システム稼働時間、累積走行距離は毎時集計されます',
      date: {
        return_latest_button: '最新を表示',
      },
      system_operating_time: {
        label: 'システム稼働時間',
      },
      accumulated_mileage: {
        label: '累積走行距離',
      },
    },
    log: {
      title: '車両状態ログ',
      category: {
        label: 'カテゴリ',
        type: {
          uptime_reset: '稼働時間リセット',
        },
      },
      contents: {
        edit_caption: '600文字以内',
      },
    },
  },
  // 通知
  notification: {
    clear_all: '表示されているすべての通知を消去',
    vehicle_alert_list: {
      title: '車両に関する通知一覧',
      clear_all: 'すべて消去',
      tooltip:
        'FMS Console を開いているときに通知されたものだけ表示されます。ページを閉じると一覧はリセットされます。',
      none: '通知はありません',
    },
    vehicle_agent_error: {
      document_link: '詳細情報',
    },
    weather: {
      precipitation: {
        title: '天候におけるハザード予報',
        message_one:
          '約{{count}}時間後に{{precipitation}}mmの降水(降雪)が予測されています',
        message_other:
          '約{{count}}時間後に{{precipitation}}mmの降水(降雪)が予測されています',
      },
    },
    update_failure: {
      type: {
        mapUpdateFailure: '$t(common.general.map)',
        agentUpdateFailure: '$t(vehicle_management.app_update.web_auto_agent)',
        firmwareUpdateFailure: '$t(vehicle_management.app_update.firmware)',
      },
      title: '$t(notification.update_failure.type.{{type}})更新失敗',
      message:
        '車両一覧ページで各車両のインフォアイコンより\n失敗理由を確認してください',
    },
    individual_parameter: {
      suggestion: {
        title: '車両固有パラメータ候補',
        message: {
          suggested:
            'パラメータ更新が必要です\n新バージョンの車両への展開後は車両の再起動をしてください',
          imported:
            '候補パラメータを取得済みです\n新バージョンの生成と展開が可能です',
        },
      },
      tampered: {
        title: '車両固有パラメータ改変有り',
        message: '車両に展開したパラメータが展開後に改変されました',
      },
    },
    telemetry_arrival_error:
      '車両のテレメトリーデータがFMSのクラウド側に届いていません。車両のネットワーク接続が正常か確認してください。\nネットワーク接続の確認方法は<docs>こちら</docs>をご確認ください。',
    custom_notification: {
      health_check: {
        title: 'ハードウェアの問題',
      },
    },
  },
};

export default ja;
